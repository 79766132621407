import * as React from "react";
import Seo from "./../../components/Seo";
import "./styles.scss";

const AboutPage = () => (
  <>
    <Seo title="About" />;
    <section
      id="about-page"
      className="grid px-10 py-40 md:px-0 place-content-center"
    >
      <div className="flex flex-col space-y-2">
        <p>Originally from Los Angeles, I moved to the UK in 2020.</p>
        <p>Other stuff goes here too...</p>
      </div>
    </section>
  </>
);

export default AboutPage;
